<template>
  <Logo class="SDULogo">
    <template #default>
      <svg
          viewBox="0 0 48 13"
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="white"
          height="150px"
          mr="24px"
          color="#fff"
          cursor="inherit"
          class="sc-iCoGMd hzwumm">
        <path d="M0 11.197c1.13 1.038 2.497 1.65 3.973 1.65 1.98 0 3.724-1.35 3.724-3.298 0-3.706-5.075-3.158-5.075-4.933 0-.848.896-1.194 1.697-1.194.723 0 1.46.314 1.9.723L7.32 2.574c-1.036-.77-2.074-1.037-3.19-1.037-1.821 0-3.628 1.13-3.628 3.095 0 3.508 5.074 2.764 5.074 4.949 0 .8-.66 1.38-1.603 1.38-1.004 0-1.962-.518-2.591-1.208L0 11.197zm16.806-4.004c0-1.328-.76-3.614-3.613-3.614h-1.57v7.227h1.57c2.852 0 3.613-2.285 3.613-3.613m2.12 0c0 .417-.05 5.498-5.969 5.498H9.502V1.694h3.455c5.92 0 5.97 5.081 5.97 5.499m11.15-5.499h-2.12v6.52c0 2.706-2.295 2.748-2.591 2.748-.296 0-2.591-.042-2.591-2.75l-.001-6.518h-2.12v6.362c0 3.41 2.24 4.792 4.712 4.792 2.472 0 4.712-1.383 4.712-4.792V1.694zM43.283 0c-4.4.958-6.092 3.278-6.092 4.994C41.787 4.44 41.225 1.556 43.282 0M48 7.783c-.935.226-2.743.505-3.948.505-2.487 0-3.042-1.419-3.165-2.032-.971-.22-2.571-.104-3.341.072.759.166 1.208.754 1.344 1.837.09-.032.47-.156.864-.156.844 0 1.559.973 1.559 2.051 0 1.596-.896 2.779-1.762 2.779-.59 0-.774-.288-.974-.288-.199 0-.36.297-.927.297-.882 0-1.853-1.307-1.853-2.794 0-.685.368-2.056 1.823-2.056.372 0 .56.098.606.124-.025-.75-.564-1.425-1.535-1.425-.826 0-2.83.796-4.28 2.023l-.43-.601c1.364-1.023 4.294-2.488 7.689-2.488.47 0 .896.034 1.254.093.164-.437.677-1.285 2.19-1.285 2.375 0 4.127 2.019 4.886 3.344" fill-rule="nonzero"></path>
      </svg>
    </template>
  </Logo>
</template>

<script>
import Logo from '@/components/site/Logo'
export default {
  name: "SDULogo",
  components: {
    Logo,
  },
}
</script>

<style scoped>

</style>
