<template>
  <section class="uk-section">
    <div class="uk-container">
      <slot name="page">

      </slot>
    </div>
  </section>
</template>

<script>
export default {
name: "LLandingPage"
}
</script>

<style scoped>

</style>
