<template>
  <Logo class="AAULogo">
    <template #default>
      <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 206.92 53.61">
        <path class="cls-1" d="M284.31,92.89l-.44-1.52a.11.11,0,0,0-.09-.07H279.9a.11.11,0,0,0-.09.07l-.44,1.52a.24.24,0,0,1-.24.17h-2.61a.18.18,0,0,1-.15-.06s0-.1,0-.18L280,81.29a.21.21,0,0,1,.22-.17h3.22a.23.23,0,0,1,.23.17l3.66,11.53a.24.24,0,0,1,0,.09c0,.1-.06.15-.19.15h-2.61A.24.24,0,0,1,284.31,92.89Zm-3.75-3.77h2.54c.07,0,.09,0,.07-.1l-1.3-4.38s0-.07-.05-.06,0,0-.05.06L280.49,89C280.48,89.09,280.5,89.12,280.56,89.12Z" transform="translate(-189.32 -73.19)"/>
        <path class="cls-1" d="M299.22,92.89l-.45-1.52s0-.07-.08-.07H294.8s-.06,0-.09.07l-.44,1.52a.22.22,0,0,1-.24.17h-2.61a.18.18,0,0,1-.15-.06.21.21,0,0,1,0-.18l3.65-11.53a.22.22,0,0,1,.22-.17h3.23a.22.22,0,0,1,.22.17l3.66,11.53a.13.13,0,0,1,0,.09c0,.1-.06.15-.19.15h-2.6A.22.22,0,0,1,299.22,92.89Zm-3.75-3.77H298c.06,0,.09,0,.06-.1l-1.29-4.38s0-.07,0-.06,0,0-.05.06L295.4,89C295.39,89.09,295.41,89.12,295.47,89.12Z" transform="translate(-189.32 -73.19)"/>
        <path class="cls-1" d="M306.63,93a.2.2,0,0,1-.06-.14V81.33a.2.2,0,0,1,.06-.15.18.18,0,0,1,.14-.06h2.42a.21.21,0,0,1,.21.21v9.22c0,.06,0,.09.08.09h5.44a.2.2,0,0,1,.15.06.2.2,0,0,1,.06.14v2a.2.2,0,0,1-.06.14.2.2,0,0,1-.15.06h-8.15A.18.18,0,0,1,306.63,93Z" transform="translate(-189.32 -73.19)"/>
        <path class="cls-1" d="M326.86,87a2.84,2.84,0,0,1,1.55,2.73,2.91,2.91,0,0,1-1.18,2.54,5.24,5.24,0,0,1-3.08.83h-4.54a.21.21,0,0,1-.2-.2V81.33a.2.2,0,0,1,.06-.15.2.2,0,0,1,.14-.06H324a5.3,5.3,0,0,1,3.15.79,2.94,2.94,0,0,1,1.06,2.54,2.51,2.51,0,0,1-1.36,2.4C326.79,86.89,326.79,86.92,326.86,87Zm-4.62-3.33v2.15c0,.06,0,.09.08.09H324a1.69,1.69,0,0,0,1.08-.3,1,1,0,0,0,.37-.85,1.07,1.07,0,0,0-.37-.87,1.69,1.69,0,0,0-1.08-.3h-1.69C322.27,83.55,322.24,83.57,322.24,83.63Zm3,6.68a1.14,1.14,0,0,0,.37-.92,1.1,1.1,0,0,0-.37-.89,1.58,1.58,0,0,0-1.06-.32h-1.83c-.05,0-.08,0-.08.09v2.27a.07.07,0,0,0,.08.08h1.83A1.63,1.63,0,0,0,325.21,90.31Z" transform="translate(-189.32 -73.19)"/>
        <path class="cls-1" d="M335,92.7a4,4,0,0,1-1.63-1.57,4.53,4.53,0,0,1-.58-2.33V85.39a4.47,4.47,0,0,1,.58-2.3A4.06,4.06,0,0,1,335,81.54a5.12,5.12,0,0,1,2.43-.55,5.19,5.19,0,0,1,2.44.55,4,4,0,0,1,1.63,1.55,4.47,4.47,0,0,1,.58,2.3V88.8a4.53,4.53,0,0,1-.58,2.33,3.92,3.92,0,0,1-1.63,1.57,5.32,5.32,0,0,1-2.44.55A5.24,5.24,0,0,1,335,92.7Zm3.75-2.4a2,2,0,0,0,.5-1.42V85.35a1.92,1.92,0,0,0-.5-1.4,1.87,1.87,0,0,0-2.63,0,2,2,0,0,0-.5,1.4v3.53a2,2,0,0,0,.5,1.42,1.9,1.9,0,0,0,2.63,0Z" transform="translate(-189.32 -73.19)"/>
        <path class="cls-1" d="M352.81,92.91l-2-4.54a.12.12,0,0,0-.1-.07h-1.13c-.05,0-.08,0-.08.09v4.47a.2.2,0,0,1-.06.14.2.2,0,0,1-.15.06h-2.42a.18.18,0,0,1-.14-.06.2.2,0,0,1-.06-.14V81.33a.2.2,0,0,1,.06-.15.18.18,0,0,1,.14-.06h5.07a4.12,4.12,0,0,1,2,.46,3.35,3.35,0,0,1,1.33,1.31,4,4,0,0,1,.46,1.94,3.38,3.38,0,0,1-.56,2A3.13,3.13,0,0,1,353.56,88a.09.09,0,0,0-.05.12l2.25,4.69a.37.37,0,0,1,0,.11c0,.09-.07.13-.19.13h-2.56A.25.25,0,0,1,352.81,92.91Zm-3.32-9.28v2.42c0,.06,0,.09.08.09h1.9a1.49,1.49,0,0,0,1-.35,1.18,1.18,0,0,0,.39-.93,1.24,1.24,0,0,0-.39-1,1.49,1.49,0,0,0-1-.35h-1.9C349.52,83.55,349.49,83.57,349.49,83.63Z" transform="translate(-189.32 -73.19)"/>
        <path class="cls-1" d="M362.18,92.65a3.87,3.87,0,0,1-1.6-1.53,4.5,4.5,0,0,1-.57-2.29V85.34a4.44,4.44,0,0,1,.57-2.28,4,4,0,0,1,1.6-1.53,5.12,5.12,0,0,1,2.4-.54,5.25,5.25,0,0,1,2.39.52,4,4,0,0,1,1.61,1.42,3.69,3.69,0,0,1,.57,2V85a.21.21,0,0,1-.21.21h-2.42a.2.2,0,0,1-.14-.06.2.2,0,0,1-.06-.15,1.55,1.55,0,0,0-.47-1.13,1.73,1.73,0,0,0-1.27-.47,1.65,1.65,0,0,0-1.26.51,1.87,1.87,0,0,0-.48,1.33v3.67a1.79,1.79,0,0,0,.53,1.33,1.83,1.83,0,0,0,1.33.51,1.77,1.77,0,0,0,1.21-.4,1.4,1.4,0,0,0,.46-1.12v-.48c0-.05,0-.08-.09-.08h-1.7a.21.21,0,0,1-.21-.21V86.6a.21.21,0,0,1,.21-.21h4.36a.21.21,0,0,1,.21.21v2.32a4.38,4.38,0,0,1-.57,2.25,3.86,3.86,0,0,1-1.6,1.5,5.24,5.24,0,0,1-2.4.53A5,5,0,0,1,362.18,92.65Z" transform="translate(-189.32 -73.19)"/>
        <path class="cls-1" d="M278.86,114a3.79,3.79,0,0,1-1.6-1.47,4.22,4.22,0,0,1-.57-2.2v-7.68a.2.2,0,0,1,.06-.14.2.2,0,0,1,.15-.06h2.42a.21.21,0,0,1,.2.2v7.68a1.81,1.81,0,0,0,.47,1.29,1.66,1.66,0,0,0,1.25.48,1.64,1.64,0,0,0,1.24-.48,1.81,1.81,0,0,0,.47-1.29v-7.68a.21.21,0,0,1,.2-.2h2.42a.2.2,0,0,1,.15.06.2.2,0,0,1,.06.14v7.68a4.31,4.31,0,0,1-.56,2.2,3.73,3.73,0,0,1-1.6,1.47,5.15,5.15,0,0,1-2.38.52A5.21,5.21,0,0,1,278.86,114Z" transform="translate(-189.32 -73.19)"/>
        <path class="cls-1" d="M297.28,102.5a.2.2,0,0,1,.15-.06h2.42a.21.21,0,0,1,.2.2v11.53a.2.2,0,0,1-.06.15.2.2,0,0,1-.14.06h-2.32a.26.26,0,0,1-.24-.14l-3.92-6.51a.08.08,0,0,0-.07,0s0,0,0,.08v6.41a.2.2,0,0,1-.06.15.18.18,0,0,1-.14.06h-2.42a.21.21,0,0,1-.21-.21V102.64a.2.2,0,0,1,.06-.14.2.2,0,0,1,.15-.06H293a.24.24,0,0,1,.24.14l3.91,6.49s0,.05.07.05,0,0,0-.08l0-6.4A.2.2,0,0,1,297.28,102.5Z" transform="translate(-189.32 -73.19)"/>
        <path class="cls-1" d="M304.87,114.32a.2.2,0,0,1-.06-.15V102.64a.21.21,0,0,1,.2-.2h2.43a.21.21,0,0,1,.2.2v11.53a.2.2,0,0,1-.06.15.2.2,0,0,1-.14.06H305A.2.2,0,0,1,304.87,114.32Z" transform="translate(-189.32 -73.19)"/>
        <path class="cls-1" d="M315.43,114.21,312,102.68l0-.07c0-.11.06-.17.19-.17h2.61a.24.24,0,0,1,.24.17l2,7.76s0,0,0,0,0,0,.05,0l1.93-7.76a.22.22,0,0,1,.24-.17h2.55a.19.19,0,0,1,.16.07.19.19,0,0,1,0,.17l-3.5,11.53a.21.21,0,0,1-.22.17h-2.59A.23.23,0,0,1,315.43,114.21Z" transform="translate(-189.32 -73.19)"/>
        <path class="cls-1" d="M334.64,104.8a.18.18,0,0,1-.14.06h-5.36c-.05,0-.08,0-.08.09V107c0,.06,0,.09.08.09h3.43a.2.2,0,0,1,.15.06.2.2,0,0,1,.06.14v2a.17.17,0,0,1-.06.14.21.21,0,0,1-.15.07h-3.43c-.05,0-.08,0-.08.08v2.25c0,.06,0,.09.08.09h5.36a.21.21,0,0,1,.2.2v2a.2.2,0,0,1-.06.15.18.18,0,0,1-.14.06h-8.07a.2.2,0,0,1-.14-.06.2.2,0,0,1-.06-.15V102.64a.21.21,0,0,1,.2-.2h8.07a.18.18,0,0,1,.14.06.2.2,0,0,1,.06.14v2A.2.2,0,0,1,334.64,104.8Z" transform="translate(-189.32 -73.19)"/>
        <path class="cls-1" d="M345.29,114.22l-2-4.53a.1.1,0,0,0-.1-.07h-1.13c-.05,0-.08,0-.08.09v4.46a.21.21,0,0,1-.21.21h-2.42a.18.18,0,0,1-.14-.06.2.2,0,0,1-.06-.15V102.64a.2.2,0,0,1,.06-.14.18.18,0,0,1,.14-.06h5.07a4,4,0,0,1,2,.46,3.29,3.29,0,0,1,1.33,1.31,3.91,3.91,0,0,1,.46,1.93,3.44,3.44,0,0,1-.56,2,3.13,3.13,0,0,1-1.57,1.21.09.09,0,0,0-.05.12l2.25,4.69a.19.19,0,0,1,0,.1c0,.09-.07.14-.19.14h-2.56A.25.25,0,0,1,345.29,114.22ZM342,105v2.42c0,.06,0,.08.08.08H344a1.44,1.44,0,0,0,1-.35,1.17,1.17,0,0,0,.39-.92,1.24,1.24,0,0,0-.39-1,1.44,1.44,0,0,0-1-.36h-1.9C342,104.86,342,104.89,342,105Z" transform="translate(-189.32 -73.19)"/>
        <path class="cls-1" d="M354.54,114.06a3.73,3.73,0,0,1-1.62-1.29,3.44,3.44,0,0,1-.57-2v-.32a.2.2,0,0,1,.06-.15.2.2,0,0,1,.15,0h2.37a.19.19,0,0,1,.14,0,.2.2,0,0,1,.06.15v.2a1.2,1.2,0,0,0,.56,1,2.51,2.51,0,0,0,1.52.42,1.65,1.65,0,0,0,1.11-.32,1,1,0,0,0,.36-.75.78.78,0,0,0-.21-.56,1.78,1.78,0,0,0-.57-.35c-.24-.1-.61-.24-1.11-.4a.9.9,0,0,0-.21-.08l-.19-.06a12.72,12.72,0,0,1-1.92-.78,4.11,4.11,0,0,1-1.4-1.16,2.84,2.84,0,0,1-.58-1.83A3.15,3.15,0,0,1,353,104a3.52,3.52,0,0,1,1.55-1.22,5.65,5.65,0,0,1,2.23-.43,5.59,5.59,0,0,1,2.36.48,3.94,3.94,0,0,1,1.65,1.35,3.41,3.41,0,0,1,.6,2v.24a.21.21,0,0,1-.07.15.17.17,0,0,1-.14.06h-2.37a.2.2,0,0,1-.15-.06.2.2,0,0,1-.05-.15v-.12a1.37,1.37,0,0,0-.53-1,2.09,2.09,0,0,0-1.42-.46,1.76,1.76,0,0,0-1,.27,1,1,0,0,0-.14,1.39,2.31,2.31,0,0,0,.75.46c.33.14.85.33,1.54.55s1.42.51,1.86.72a3.68,3.68,0,0,1,1.25,1,2.7,2.7,0,0,1,.59,1.81,3.26,3.26,0,0,1-.56,1.91,3.59,3.59,0,0,1-1.58,1.24,6,6,0,0,1-2.36.43A6.22,6.22,0,0,1,354.54,114.06Z" transform="translate(-189.32 -73.19)"/>
        <path class="cls-1" d="M366,114.32a.2.2,0,0,1-.06-.15V102.64a.2.2,0,0,1,.06-.14.2.2,0,0,1,.15-.06h2.42a.21.21,0,0,1,.2.2v11.53a.2.2,0,0,1-.06.15.2.2,0,0,1-.14.06h-2.42A.2.2,0,0,1,366,114.32Z" transform="translate(-189.32 -73.19)"/>
        <path class="cls-1" d="M382.32,102.5a.2.2,0,0,1,.06.14v2a.2.2,0,0,1-.06.15.2.2,0,0,1-.14.06h-3a.07.07,0,0,0-.08.08v9.21a.21.21,0,0,1-.21.21h-2.42a.2.2,0,0,1-.14-.06.2.2,0,0,1-.06-.15V105s0-.08-.09-.08h-2.88a.17.17,0,0,1-.14-.06.2.2,0,0,1-.06-.15v-2a.2.2,0,0,1,.06-.14.17.17,0,0,1,.14-.06h8.87A.2.2,0,0,1,382.32,102.5Z" transform="translate(-189.32 -73.19)"/>
        <path class="cls-1" d="M389.89,114.28a.18.18,0,0,1-.06-.14v-4a.25.25,0,0,0,0-.1l-3.5-7.32a.33.33,0,0,1,0-.1c0-.09.06-.14.19-.14h2.62a.25.25,0,0,1,.24.15l1.86,4.32c0,.07.07.07.1,0l1.88-4.32a.23.23,0,0,1,.24-.15h2.64a.18.18,0,0,1,.16.06s0,.1,0,.18L392.67,110s0,0,0,.1v4a.18.18,0,0,1-.06.14.2.2,0,0,1-.15.06H390A.2.2,0,0,1,389.89,114.28Z" transform="translate(-189.32 -73.19)"/>
        <path class="cls-1" d="M240.54,114.4a21.11,21.11,0,0,1-5.12-2.55c-6-4.25-8.68-9.17-4.85-19.58a40.3,40.3,0,0,0,3-16.25c-.07-.06-.37-.25-.43-.3-1.2,7.23-3.19,10.8-7.69,18.1-10.16,16.51-.6,26.61,3.91,30.26A27.9,27.9,0,0,0,240.54,114.4Z" transform="translate(-189.32 -73.19)"/>
        <path class="cls-1" d="M220.63,93.25c7.09-9,10-12.7,10.62-18.72l-.38-.2C229,81.6,225,84.77,213.12,93c-12.34,8.53-11.9,21.38-8.54,30.76a27.86,27.86,0,0,0,12.71,3.07c1,0,1.94-.06,2.89-.15C211.7,113.09,212.36,103.7,220.63,93.25Z" transform="translate(-189.32 -73.19)"/>
        <path class="cls-1" d="M215.8,86.45c8-4,11.61-8,13.19-13.09l-.38-.17c-4.56,7.81-10.21,8.39-23.08,10.54-7.39,1.23-11.41,4.46-15.56,9a31,31,0,0,0-.65,6,27.89,27.89,0,0,0,6.42,17.94C196,95.68,205.75,91.43,215.8,86.45Z" transform="translate(-189.32 -73.19)"/>
      </svg>
    </template>
  </Logo>
</template>

<script>
import Logo from '@/components/site/Logo'
import {useMediaRepo} from "@c/use/useMediaRepo";

export default {
  name: "AAULogo",
  components: {
    Logo,
  },
  setup (props) {
    const {imageRepoUrl} = useMediaRepo(props)
    return {
      imageRepoUrl
    }
  },
}
</script>

<style scoped>
.cls-1 {
  fill: white
}
</style>
