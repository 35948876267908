<template>
  <div
      class="course"
      :class="{clickToNavigate: !canEdit}"
      @click="onCourseCardClick"
  >
    <LLeftDrawerRow
        v-if="course"
        :show-drawer="showImageDetails"
        uk-height-match
    >
      <template
          #drawer
      >
        <div class="uk-card-default uk-padding-small uk-background-muted uk-height-1-1 settings">
          <ZoomableImageSettings
              v-if="course"
              :document-name="documentId + 'Image'"
              :create-if-not-exists="true"
          ></ZoomableImageSettings>
        </div>
      </template>
      <template #body>

        <div class="card uk-card-small uk-card-default uk-card-body">
          <div class="uk-card-media-left uk-padding-remove-left">
            <div class="logo frame">
              <ZoomableImage
                  :document-name="documentId + 'Image'"
                  @click="onImageClick($event)"
              ></ZoomableImage>
            </div>

          </div>
          <div class="content uk-text-left">
            <h1>
              <EditableDiv
                  class="title"
                  :isEditable="canEdit"
                  placeholder="Title"
                  :value="decodedCourseName"
                  @blur="renameCourse"
                  @keyup.enter.stop="renameCourse"
                  @click.stop=""
              ></EditableDiv>
            </h1>
            <MarkdownContent
                :collection-id="collectionId"
                :document-id="documentId"
                field-path="brief"
            ></MarkdownContent>
            <div
                v-if="canEdit"
                class="goToCourse"
            >
              <div
                  class="uk-button uk-button-primary"
                  @click="goToCourse"
              >
                go to Course
              </div>
            </div>
          </div>
        </div>
      </template>
    </LLeftDrawerRow>
  </div>
</template>
<script>
import EditableDiv from "@/components/utilities/EditableDiv";
import LLeftDrawerRow from "@/components/layout/LLeftDrawerRow";
import ZoomableImageSettings from "@/components/image/zoomableImage/ZoomableImageSettings";
import ZoomableImage from "@/components/image/zoomableImage/ZoomableImage";
import MarkdownContent from "@/components/service/document/md/MarkdownContent";
import {usePermissions} from "@/components/use/usePermissions";
import {computed, ref, watch} from "vue";
import {useCourse} from "@/components/use/serviceAssets/documentEditors/Course";
import {useStore} from "vuex";
import {useCollection} from "@u/serviceAssets/useCollection";
import {useDocument} from "@u/serviceAssets/useDocument";

export default {
  name: "Course",
  components: {
    EditableDiv,
    LLeftDrawerRow,
    MarkdownContent,
    ZoomableImage,
    ZoomableImageSettings,
  },
  props: {
    collectionId: {
      type: String,
      required: true
    },
    documentId: {
      type: String,
      required: true
    },
  },
  setup(props) {
    const store = useStore()
    const {canEdit} = usePermissions(props)
    const {loadCollection} = useCollection()
    const {loadDocument} = useDocument()
    const {course, courseName, decodedCourseName, goToCourse, renameCourse} = useCourse(props)
    const service = computed(() => store.state.service[store.state.serviceName])
    const collection = computed(() => store.state.collection[props.collectionId] || Object.values(store.state.collection).find(c => c.name === props.collectionName))
    const showImageDetails = ref(false)
    const toggleImageEditor = () => showImageDetails.value = !showImageDetails.value && canEdit.value
    const onCourseCardClick = e => {
      if (!canEdit.value) goToCourse()
      e.stopPropagation() // vue's .stop modifier fails with: "e is undefined"
    }
    const onImageClick = event => {
      if (canEdit.value) toggleImageEditor()
      else goToCourse()

      event?.stopPropagation() // vue's .stop modifier fails with: "e is undefined"
    }

    watch(service, service => {
      if (service) loadCollection({collectionId: props.collectionId})
    }, {immediate: true})

    watch(collection, collection => {
      if (collection) loadDocument({
        collectionId: props.collectionId,
        documentId: props.documentId
      }, 'CourseCard.vue')
    }, {immediate: true})

    return {
      canEdit,
      course,
      courseName,
      decodedCourseName,
      goToCourse,
      onCourseCardClick,
      service,
      showImageDetails,
      onImageClick,
      renameCourse
    }
  }
}
</script>
<style scoped lang="stylus">
.course

  .settings
    background-color #363636

  .card
    min-width 500px
    display grid
    grid-template-columns 225px 1fr

    .logo
      width 240px
      height 240px
      overflow hidden
      cursor pointer

    .deleteButton
      float right

    .title
    .affiliation
    .focus
    .role
      font-size 80%

    .name
      font-size 110%
      font-weight lighter

    .role
    .focus
      font-style italic

    .title
    .affiliation
      font-weight bold

    .content
      padding-left 2rem

    :after
      width 100%

.course.clickToNavigate
  cursor: pointer
</style>
