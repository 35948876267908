<template>
  <div class="layout squareLeftRow">
    <div class="uk-card uk-card-default uk-width-1-1">
      <div class="uk-card-header">
        <div class="uk-grid uk-flex-start" uk-grid>
          <div>
            <slot name="square">

            </slot>
          </div>
          <div class="uk-grid uk-child-width-1-1 uk-width-expand" uk-grid>
            <slot name="body">

            </slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LSquareLeftRow"
}
</script>

<style scoped lang="stylus">

</style>
