<template>
  <div
      class="layout grid"
      :class="{showingDrawer: showingDrawer}"
  >
    <div class="uk-width-1-1 uk-flex">
      <transition name="fade">
        <div
            class="left drawer uk-width-1-6"
            v-if="showingDrawer"
        >
          <slot name="drawer">
drawer
          </slot>
        </div>
      </transition>
      <div class="right uk-width-expand">
        <slot name="body">
body
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import {computed} from "vue";

export default {
  name: "LLeftDrawerRow",
  props: {
    showDrawer: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const showingDrawer = computed(() => props.showDrawer)

    return {
      showingDrawer
    }
  }
}
</script>

<style scoped lang="stylus">

  .left
    width 12rem

  .fade-enter-active
  .fade-leave-active
    transition opacity 0.5s ease, width 0.5s ease

  .fade-enter-from
  .fade-leave-to
    opacity 0
    width 0


</style>
