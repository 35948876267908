<template>
  <div class="CHCAA center logo">
    <svg
viewBox="0 0 1276 1458"
fill-rule="evenodd"
clip-rule="evenodd"
stroke-linecap="round"
fill="currentcolor"
:width="size + 'px'"
:height="size + 'px'"
cursor="inherit"
class="sc-iBPRYJ IZCfe"
><path
d="M3634.01 7695.94l306.86-590.55-511.43 295.27 204.57 295.28z"
fill="url(#logo_esc_svg___Linear1)"
transform="matrix(1 0 0 1 -3303.051 -6967.127)"
></path><path
d="M3429.44 7991.21l204.57-295.27-204.57-295.28v590.55z"
fill="url(#logo_esc_svg___Linear2)"
transform="matrix(1 0 0 1 -3303.051 -6967.127)"
></path><path
d="M3940.87 8286.49l-306.86-590.55-204.57 295.27 511.43 295.28z"
fill="url(#logo_esc_svg___Linear3)"
transform="matrix(1 0 0 1 -3303.051 -6967.127)"
></path><path
d="M3940.87 8286.49l153.43-324.8-460.29-265.75 306.86 590.55z"
fill="url(#logo_esc_svg___Linear4)"
transform="matrix(1 0 0 1 -3303.051 -6967.127)"
></path><path
d="M4452.3 7991.21l-358-29.52-153.43 324.8 511.43-295.28z"
fill="url(#logo_esc_svg___Linear5)"
transform="matrix(1 0 0 1 -3303.051 -6967.127)"
></path><path
d="M4196.59 7489.24l-102.29 472.45-460.29-265.75 562.58-206.7z"
fill="url(#logo_esc_svg___Linear6)"
transform="matrix(1 0 0 1 -3303.051 -6967.127)"
></path><path
d="M3940.87 7105.39l255.72 383.85-562.58 206.7 306.86-590.55z"
fill="url(#logo_esc_svg___Linear7)"
transform="matrix(1 0 0 1 -3303.051 -6967.127)"
></path><path
d="M4452.3 7400.66l-255.71 88.58-255.72-383.85 511.43 295.27z"
fill="url(#logo_esc_svg___Linear8)"
transform="matrix(1 0 0 1 -3303.051 -6967.127)"
></path><path
d="M4452.3 7991.21l-255.71-501.97-102.29 472.45 358 29.52z"
fill="url(#logo_esc_svg___Linear9)"
transform="matrix(1 0 0 1 -3303.051 -6967.127)"
></path><path
d="M4452.3 7400.66l-255.71 88.58 255.71 501.97v-590.55z"
fill="url(#logo_esc_svg___Linear10)"
transform="matrix(1 0 0 1 -3303.051 -6967.127)"
></path><g stroke="#fff"><path
d="M3582.87 7725.47l358-708.67-588.15 339.57 230.15 369.1zm-230.15 310.03l588.15 339.57-358-649.6-230.15 310.03zm588.15-1018.7l-358 708.67 664.86-265.75-306.86-442.92z"
fill="none"
stroke-opacity="0.4"
stroke-width="25"
transform="matrix(1 0 0 1 -3303.051 -6967.127)"
></path><path
d="M3352.72 8035.5l230.15-310.03-230.15-369.1v679.13zm741.58-14.76l153.43-561.02-664.86 265.75 511.43 295.27z"
fill="none"
stroke-opacity="0.4"
stroke-width="25"
transform="matrix(1 0 0 1 -3303.051 -6967.127)"
></path><path
d="M3582.87 7725.47l511.43 295.27-153.43 354.33-358-649.6zm358-708.67l306.86 442.92 281.29-103.35-588.15-339.57zm588.15 1018.7l-281.29-575.78-153.43 561.02 434.72 14.76z"
fill="none"
stroke-opacity="0.4"
stroke-width="25"
transform="matrix(1 0 0 1 -3303.051 -6967.127)"
></path><path
d="M3940.87 8375.07l153.43-354.33 434.72 14.76-588.15 339.57zm588.15-1018.7v679.13l-281.29-575.78 281.29-103.35z"
fill="none"
stroke-opacity="0.4"
stroke-width="25"
transform="matrix(1 0 0 1 -3303.051 -6967.127)"
></path><path
d="M3940.87 7016.8l588.15 339.57v679.13l-588.15 339.57-588.15-339.57v-679.13l588.15-339.57z"
fill="none"
stroke-opacity="0.4"
stroke-width="25"
transform="matrix(1 0 0 1 -3303.051 -6967.127)"
></path><circle
cx="3940.87"
cy="7046.33"
r="25.572"
fill="#fcfcfc"
stroke-opacity="0.569"
stroke-width="10.31"
transform="matrix(1.61658 0 0 1.61658 -5732.917 -11341.288)"
></circle><circle
cx="3940.87"
cy="7046.33"
r="25.572"
fill="#fcfcfc"
stroke-opacity="0.569"
stroke-width="10.31"
transform="matrix(1.61658 0 0 1.61658 -5144.767 -10322.588)"
></circle><circle
cx="3940.87"
cy="7046.33"
r="25.572"
fill="#fcfcfc"
stroke-opacity="0.569"
stroke-width="10.31"
transform="matrix(1.61658 0 0 1.61658 -5732.917 -9983.018)"
></circle><circle
cx="3940.87"
cy="7046.33"
r="25.572"
fill="#fcfcfc"
stroke-opacity="0.569"
stroke-width="10.31"
transform="matrix(1.61658 0 0 1.61658 -6321.056 -10322.588)"
></circle><circle
cx="3940.87"
cy="7046.33"
r="25.572"
fill="#fcfcfc"
stroke-opacity="0.569"
stroke-width="10.31"
transform="matrix(1.61658 0 0 1.61658 -6321.056 -11001.718)"
></circle><circle
cx="3940.87"
cy="7046.33"
r="25.572"
fill="#fcfcfc"
stroke-opacity="0.569"
stroke-width="10.31"
transform="matrix(1.61658 0 0 1.61658 -5144.767 -11001.718)"
></circle><circle
cx="3940.87"
cy="7046.33"
r="25.572"
fill="#fcfcfc"
stroke-opacity="0.569"
stroke-width="10.02"
transform="matrix(2.07846 0 0 2.07846 -7246.265 -14152.938)"
></circle><circle
cx="3940.87"
cy="7046.33"
r="25.572"
fill="#fcfcfc"
stroke-opacity="0.569"
stroke-width="10.02"
transform="matrix(2.07846 0 0 2.07846 -7399.695 -13591.908)"
></circle><circle
cx="3940.87"
cy="7046.33"
r="25.572"
fill="#fcfcfc"
stroke-opacity="0.569"
stroke-width="10.02"
transform="matrix(2.07846 0 0 2.07846 -7911.124 -13887.188)"
></circle></g><defs><linearGradient
id="logo_esc_svg___Linear1"
x2="1"
gradientUnits="userSpaceOnUse"
gradientTransform="rotate(-17.467 25780.093 -7698.299) scale(374.554)"
><stop
offset="0"
stop-color="#a1c4f5"
></stop><stop
offset="1"
stop-color="#2a79e7"
></stop></linearGradient><linearGradient
id="logo_esc_svg___Linear2"
x2="1"
gradientUnits="userSpaceOnUse"
gradientTransform="scale(163.849) rotate(-13.76 204.771 -63.999)"
><stop
offset="0"
stop-color="#649dee"
></stop><stop
offset="1"
stop-color="#b8d3f7"
></stop></linearGradient><linearGradient
id="logo_esc_svg___Linear3"
x2="1"
gradientUnits="userSpaceOnUse"
gradientTransform="rotate(-137.158 3523.525 3336.152) scale(383.627)"
><stop
offset="0"
stop-color="#124fa3"
></stop><stop
offset="1"
stop-color="#1764d1"
></stop></linearGradient><linearGradient
id="logo_esc_svg___Linear4"
x2="1"
gradientUnits="userSpaceOnUse"
gradientTransform="scale(278.2815) rotate(-3.202 522.325 -229.105)"
><stop
offset="0"
stop-color="#124fa3"
></stop><stop
offset="0.58"
stop-color="#1b5ab1"
></stop><stop
offset="0.8"
stop-color="#2e71ce"
></stop><stop
offset="1"
stop-color="#4087ea"
></stop></linearGradient><linearGradient
id="logo_esc_svg___Linear5"
x2="1"
gradientUnits="userSpaceOnUse"
gradientTransform="rotate(-27.959 18409.282 -3961.098) scale(503.97)"
><stop
offset="0"
stop-color="#2f7ce8"
></stop><stop
offset="1"
stop-color="#a7c7f5"
></stop></linearGradient><linearGradient
id="logo_esc_svg___Linear6"
x2="1"
gradientUnits="userSpaceOnUse"
gradientTransform="rotate(-90 5968.835 1925.675) scale(343.498)"
><stop
offset="0"
stop-color="#5494ec"
></stop><stop
offset="0.46"
stop-color="#2366c4"
></stop><stop
offset="1"
stop-color="#1559b9"
></stop></linearGradient><linearGradient
id="logo_esc_svg___Linear7"
x2="1"
gradientUnits="userSpaceOnUse"
gradientTransform="scale(455.028) rotate(-67.007 16.79 2.07)"
><stop
offset="0"
stop-color="#1e71e6"
></stop><stop
offset="1"
stop-color="#10448d"
></stop></linearGradient><linearGradient
id="logo_esc_svg___Linear8"
x2="1"
gradientUnits="userSpaceOnUse"
gradientTransform="scale(460.207) rotate(27.597 -27.414 25.698)"
><stop
offset="0"
stop-color="#124ea2"
></stop><stop
offset="1"
stop-color="#4086ea"
></stop></linearGradient><linearGradient
id="logo_esc_svg___Linear9"
x2="1"
gradientUnits="userSpaceOnUse"
gradientTransform="scale(-356.846) rotate(69.89 9.809 -19.82)"
><stop
offset="0"
stop-color="#dbe8fb"
></stop><stop
offset="1"
stop-color="#1763cd"
></stop></linearGradient><linearGradient
id="logo_esc_svg___Linear10"
x2="1"
gradientUnits="userSpaceOnUse"
gradientTransform="scale(-530.3906) rotate(86.711 3.725 -11.811)"
><stop
offset="0"
stop-color="#0e3e80"
></stop><stop
offset="1"
stop-color="#2475e7"
></stop></linearGradient></defs></svg>
  </div>
</template>

<script>
export default {
name: "Logo",
  props: {
    size: {
      type: String,
      default: '38',
      validator: value => 38 <= parseInt(value) && parseInt(value) <= 900
    }
  }
}
</script>

<style scoped lang="stylus">
  img
    height $menuBarSize

</style>
