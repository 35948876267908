<template>
  <Logo class="CEDHARLogo">
    <template #default>
      <img
          v-if="color.toLowerCase() === 'black'"
          src="../../assets/CEDHARLogo_black.png"
          alt="Logo of the Center for Digital History Aarhus, black capital block letters spelling CEDHAR with an underline"
      >
      <img
          v-else-if="color.toLowerCase() === 'red'"
          src="../../assets/CEDHARLogo_red.png"
          alt="Logo of the Center for Digital History Aarhus, red capital block letters spelling CEDHAR with an underline"
      >
      <img
          v-else-if="color.toLowerCase() === 'white'"
          src="../../assets/CEDHARLogo_white.png"
          alt="Logo of the Center for Digital History Aarhus, white capital block letters spelling CEDHAR with an underline"
      >
    </template>
  </Logo>
</template>

<script>
import Logo from '@/components/site/Logo'

export default {
  name: "CEDHARLogo",
  components: {
    Logo,
  },
  props: {
    color: {
      type: String,
      default: 'black',
      validate: value => ['black', 'red', 'white'].includes(value.toLowerCase())
    }
  }
}
</script>

<style scoped>

</style>
