<template>
  <ul class="courseContentList" uk-accordion>
    <CourseModuleAccordionItem
        v-for="(module, index) in modules"
        :key="module.repoId"
        :class="{'uk-open': selectedIndex === index}"
        :courseName="courseName"
        :documentId="module.repoId"
        :index="index"
        :isSelected="selectedIndex === index"
        @click.stop.prevent="selectedIndex = index"
        @delete="onModuleRemove"
    ></CourseModuleAccordionItem>
    <li
        v-if="canEdit"
        class="uk-button uk-button-primary uk-width-1-1 notItem"
        title="Add an existing Module by name"
        @click="showModulePicker=!showModulePicker"
    >
      Add Course Module
    </li>
    <li
        class="uk-width-1-1 uk-card-small uk-card-default uk-card-body uk-background-muted uk-margin-remove item"
        v-for="name in availableModuleNames"
        :key="name"
        @click="addModuleByName(name)"
    >
      <a
          class="uk-text-capitalize"
      >{{ name }}</a>
    </li>
  </ul>
</template>

<script>
import CourseModuleAccordionItem from "@/components/learningMaterials/course/courseModule/CourseModuleAccordionItem";
import {computed, ref, watch} from "vue";
import {usePermissions} from "@/components/use/usePermissions";
import {useCourse} from "@/components/use/serviceAssets/documentEditors/Course";
import {useStore} from "vuex";
import {useCollection} from "@u/serviceAssets/useCollection";
import {useDocument} from "@u/serviceAssets/useDocument";

export default {
  name: "CourseContentList",
  components: {
    CourseModuleAccordionItem,
  },
  props: {
    collectionName: {
      type: String,
      default: 'courses',
      validate: value => value === 'courses'
    },
    courseName: {
      type: String,
      required: true
    },
    documentNameProperty: {
      type: String,
      default: 'courseName',
      validate: value => value === 'courseName'
    },
    origin: {
      type: String,
      default: 'CourseContentList'
    }

  },
  setup(props) {
    const store = useStore()
    const {canEdit} = usePermissions(props)
    const {loadCollection} = useCollection()
    const {loadDocument} = useDocument()
    const service = computed(() => store.state.service[store.state.serviceName])
    const collection = computed(() => store.state.collection[props.collectionId] || Object.values(store.state.collection).find(c => c.name === props.collectionName))
    const {course, modules, patchCourseModules} = useCourse(props)

    const showModulePicker = ref(false)
    const selectedIndex = ref(-1)

    const availableModuleNames = computed(() => { // expensive, do collections load completely populated?
      let availableModuleNames = []
      if (canEdit.value && showModulePicker.value) {
        const modulesCollection = Object.values(store.state.collection)
            .find(d => d.name === 'modules')
        const existingModules = Object.values(store.state.document)
            .filter(d => d.parentRepoId === modulesCollection.repoId)
        availableModuleNames = existingModules
            .filter(d => !course.value.modules.includes(d.repoId))
            .map(d => d.name)
      }
      return availableModuleNames
    })

    const addModuleByName = name => {
      const module = Object.values(store.state.document)
          .find(doc => doc.name === name) // todo: extract to useModule.getModuleByName
      patchCourseModules([
        ...course.value.modules,
        module.repoId
      ])
    }
    const onModuleRemove = deleteArgs => {
      const fewerModules = course.value.modules.reduce((list, m) => {
        if (m !== deleteArgs.documentId) list.push(m)
        return list
      }, [])
      patchCourseModules(fewerModules)
    }

    watch (service, service => {
      if (service) loadCollection({ collectionName: props.collectionName })
    }, {immediate: true})

    watch(collection, collection => {
      if (collection) loadDocument({
        collectionName: props.collectionName,
        documentName: props.courseName
      }, 'CourseContentList.vue')
    }, {immediate: true})

    return {
      addModuleByName,
      availableModuleNames,
      canEdit,
      modules,
      onModuleRemove,
      selectedIndex,
      showModulePicker,
    }
  }
}
</script>

<style scoped lang="stylus">
.courseContentList>:not(:first-child, .notItem) {
  border-top: 1px solid #e38928;
}

.button {
  background-color: #1f768f;
  color: white;
}

li {
  cursor: pointer;
}
li.selected {
  background-color: aliceblue;
}
li:hover {
 box-shadow: inset 0 0 .3rem #e3892840;
}
li a {
  color: #1a1a1a !important;
}

</style>
