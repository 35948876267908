<template>
  <LCourses>
    <template #content>

      <!--  needs to go to content column-->
      <section class="uk-section courses">
        <div v-if="service"
        >
          <div class="uk-container">
            <PermanentDocument
                class="uk-margin"
                collection-name="texts"
                document-name="courses-header"
            ></PermanentDocument>
          </div>

          <div
              class="uk-border-rounded"
              style="background-color: #eff0f3; box-shadow: inset white 0 0 1rem;"
          >
            <div class="uk-container">
              <h2 class="uk-margin-top uk-text-bold">Courses</h2>
              <DocumentColumnLayout
                  data-type="Course"
                  encoding="json"
                  collection-name="courses"
                  new-document-name="Unnamed Course"
                  aliasAttributeName=".name"
                  create-button-label="Add Course"
                  no-documents-type-label="course"
                  :isFullWidth="false"
                  :default-document-content="defaultCourse"
              ></DocumentColumnLayout>
            </div>
          </div>
          <div
              class="uk-container"
              v-if="canEdit"
          >
            <h2 class="uk-margin-top uk-text-bold">Course Modules</h2>
            <DocumentColumnLayout
                data-type="Module"
                encoding="json"
                :isInverted="true"
                collection-name="modules"
                create-button-label="Add Module"
                new-document-name="Unnamed Module"
                no-documents-type-label="module"
                :isSortable="false"
                :isFullWidth="false"
                :default-document-content="defaultModule"
                :relay-event="true"
                @create="onCreateModule"
            ></DocumentColumnLayout>
          </div>
        </div>
      </section>

    </template>
  </LCourses>
</template>

<script>
import PermanentDocument from "@/components/service/document/md/PermanentDocument";
import DocumentColumnLayout from "@/components/service/collection/DocumentColumnLayout";
import {useDocumentTemplate} from "@/components/use/serviceAssets/documentEditors/useDocumentTemplate";
import {usePermissions} from "@/components/use/usePermissions";
import {computed, watch} from "vue";
import {useCollection} from "../use/serviceAssets/useCollection";
import {useStore} from "vuex";
import {v4 as uuid} from 'uuid'
import {decode, encode} from "@c/service/document/lib/parse";
import LCourses from "@c/learningMaterials/LCourses";

export default {
  name: 'Courses',
  components: {
    DocumentColumnLayout,
    LCourses,
    PermanentDocument,
  },
  setup() {
    const store = useStore()
    const {canEdit} = usePermissions()
    const {createCollectionDocument, loadCollection} = useCollection()
    const {getDocumentTemplate} = useDocumentTemplate()
    const service = computed(() => store.state.service[store.state.serviceName])
    const defaultCourse = getDocumentTemplate('Course').content
    const defaultModule = getDocumentTemplate('Module').content

    watch(service, service => {
      if (service) loadCollection({collectionName: 'courses'})
    }, {immediate: true})

    const onCreateModule = createModuleArgs => {
      const docArrayName = uuid()
      createCollectionDocument({
        serviceId: store.state.serviceName,
        collectionId: 'documentArrays',
        documentName: docArrayName,
        dataType: 'DocumentArray',
        encoding: 'array',
        content: []
      })
          .then(() => {
            const payload = decode(createModuleArgs.content, 'json')
            payload.documentArrayId = Object.values(store.state.document).find(d => d.name === docArrayName)?.repoId
            createModuleArgs.content = encode(payload, 'json')
            createCollectionDocument(createModuleArgs)
          })
    }
    return {
      canEdit,
      defaultCourse,
      defaultModule,
      onCreateModule,
      service,
    }
  }
}

</script>

<style scoped>
</style>
