<template>
  <div
      class="lAutoColumns"
      :style="{columnCount}"
  >
    <slot name="items">

    </slot>
  </div>
</template>

<script>
export default {
  name: "LAutoColumns",
  props: {
    columnCount: {
      type: Number,
      default: 2
    }
  }
}
</script>

<style scoped>
.lAutoColumns {
  column-width: auto;
}

.lAutoColumns > * {
  margin-bottom: .5rem;
}
</style>
