<template>
  <Logo class="AULogo">
    <template #default>
      <img src="../../assets/AULogo.png" alt="Aarhus University">
    </template>
  </Logo>
</template>

<script>
import Logo from '@/components/site/Logo'
export default {
  name: "AULogo",
  components: {
    Logo,
  },
}
</script>

<style scoped>

</style>
