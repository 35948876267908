<template>
  <div class="courseContent uk-height-viewport">
    <LCourseNavigation
        :collection-name="collectionName"
        :courseName="courseName"
        :documentNameProperty="documentNameProperty"
    >

      <template #content>

        <LLandingPage>
          <template #page>

            <LSections v-if="course">
              <template #sections>

                <h1 class="uk-text-center">{{ decodedCourseName }}</h1>
                <MarkdownContent
                    v-if="collection"
                    :collection-id="collection.repoId"
                    :document-id="courseDocument.repoId"
                    fieldPath="overview"
                ></MarkdownContent>
                <LTwoColumns>
                  <template #items>

                    <div class="uk-card-small prerequisites card">
                      <div class="uk-text-center title">Course Prerequisites</div>
                      <div class="body">

                        <MarkdownContent
                            v-if="collection"
                            :collection-id="collection.repoId"
                            :document-id="courseDocument.repoId"
                            fieldPath="prerequisites"
                        ></MarkdownContent>
                      </div>
                    </div>

                    <div class="uk-card-small objectives card">
                      <div class="uk-text-center title">Learning Objectives</div>
                      <div class="body">

                        <MarkdownContent
                            v-if="collection"
                            :collection-id="collection.repoId"
                            :document-id="courseDocument.repoId"
                            fieldPath="objectives"
                        ></MarkdownContent>
                      </div>
                    </div>

                  </template>
                </LTwoColumns>

                <MarkdownContent
                    v-if="collection"
                    :collection-id="collection.repoId"
                    :document-id="courseDocument.repoId"
                    fieldPath="resources"
                ></MarkdownContent>

              </template>

            </LSections>
            <div v-else>
              loading course...
            </div>
          </template>
        </LLandingPage>

      </template>
    </LCourseNavigation>

  </div>
</template>

<script>
import MarkdownContent from "@/components/service/document/md/MarkdownContent";
import LSections from "../../layout/LSections";
import LLandingPage from "../../layout/LLandingPage";
import {useCourse} from "@/components/use/serviceAssets/documentEditors/Course";
import {usePermissions} from "@/components/use/usePermissions";
import {useVersion} from "@/components/use/serviceAssets/useVersion";
import LCourseNavigation from '@/components/learningMaterials/course/LCourseNavigation'
import LTwoColumns from "@/components/layout/LTwoColumns";
import {computed, watch} from "vue";
import {SERVICE_ACRONYM} from '../../../../../config'
import {useStore} from "vuex";
import {useDocument} from "@u/serviceAssets/useDocument";

export default {
  name: "CourseContent",
  components: {
    LTwoColumns,
    LCourseNavigation,
    // BreadCrumbs,
    LLandingPage,
    LSections,
    MarkdownContent,
  },
  props: {
    collectionName: {
      type: String,
      default: 'courses',
      validate: value => value === 'courses'
    },
    courseName: {
      type: String,
      required: true
    },
    documentNameProperty: {
      type: String,
      default: 'courseName',
      validate: value => value === 'courseName'
    }
  },
  setup(props) {
    const store = useStore()
    const {canEdit} = usePermissions(props)
    const { loadDocument} = useDocument()
    const {patchVersionPayloadAttribute} = useVersion()
    const courseDocument = computed(() => store.state.document[props.documentId] || Object.values(store.state.document).find(d => d.name === (props.documentName || props[props.documentNameProperty])))

    const {course, decodedCourseName} = useCourse(props)
    const collection = computed(() => store.state.collection[props.collectionId] || Object.values(store.state.collection).find(c => c.name === props.collectionName))

    // todo: move to course controller
    const onObjectivesUpdate = update => patchVersionPayloadAttribute({
      serviceId: SERVICE_ACRONYM,
      collectionId: collection.value.repoId,
      documentId: courseDocument.value.repoId,
      attributeName: 'objectives',
      value: update
    })

    const onPrerequisitesUpdate = update => patchVersionPayloadAttribute({
      serviceId: SERVICE_ACRONYM,
      collectionId: collection.value.repoId,
      documentId: courseDocument.value.repoId,
      attributeName: 'prerequisites',
      value: update
    })

    watch (collection, collection => {
      if (collection ) loadDocument({
        collectionName: props.collectionName,
        documentName: props.courseName
      }, 'Course.vue')
    }, {immediate: true})
    return {
      canEdit,
      course,
      collection,
      courseDocument,
      decodedCourseName,
      onObjectivesUpdate,
      onPrerequisitesUpdate,
    }
  }
}
</script>

<style scoped lang="stylus">

.courseContent
  flex: 1;
  background-color white

  .prerequisites, .objectives
    .title
      font-size: 130%;
      color: white

    .body
      min-height: 180px !important;
      padding: 1rem 1rem;

  .prerequisites
    background-color #f0f8ff

    .title
      background-color: #003D73;

  .objectives
    background-color #e3892820

    .title
      background-color #e38928
</style>
