<template>
  <li
      class="item uk-width-1-1 uk-card-small uk-card-default uk-card-body uk-margin-remove accordion-item"
      :class="{isCurrent: isCurrent, isSelected: isSelected}"
      v-if="module"
  >
    <a
        class="uk-accordion-title uk-text-capitalize"
    >
      {{ index + 1 }} - {{ decodedModuleName }}
      <!--      <span class="relativeTime">{{ relativeTime }}</span> -->
    </a>
    <div
        v-if=isSelected
        class="uk-accordion-content"
    >
      <div>
        <!--        <div class="field">-->
        <!--          <label>Time: </label>-->
        <!--          &lt;!&ndash;          <Datepicker&ndash;&gt;-->
        <!--          &lt;!&ndash;              class="picker"&ndash;&gt;-->
        <!--          &lt;!&ndash;              v-model="moduleTime"&ndash;&gt;-->
        <!--          &lt;!&ndash;              @change="value => updateAttribute('time', value)"&ndash;&gt;-->
        <!--          &lt;!&ndash;          ></Datepicker>&ndash;&gt;-->
        <!--          <EditableDiv-->
        <!--              class="picker"-->
        <!--              :isEditable="canEdit"-->
        <!--              placeholder="Date and Time"-->
        <!--              :value="''+module.time"-->
        <!--              @change="value => updateAttribute('time', value)"-->
        <!--          ></EditableDiv>-->
        <!--        </div>-->
        <!--        <div class="field">-->
        <!--          <label>Location: </label>-->
        <!--          <EditableDiv-->
        <!--              class="location"-->
        <!--              :isEditable="canEdit"-->
        <!--              placeholder="Location"-->
        <!--              :value="module.location"-->
        <!--              @change="value => updateAttribute('location', value)"-->
        <!--          ></EditableDiv>-->
        <!--        </div>-->
        <!--        <hr>-->
        <MarkdownContent
            v-if="collection && moduleDocument"
            :collection-id="collection.repoId"
            :document-id="moduleDocument.repoId"
            fieldPath="brief"
            :relayDeleteEvent="true"
            deleteElaboration="It will remove the any reference to this module from the course."
            @delete="onDelete"
        ></MarkdownContent>
        <div
            v-if="!isCurrent"
            class="enterAction uk-margin-top"
            @click="goToModule"
        >
          <span class="enter uk-text-primary">Open module</span>
          <div
              style="transform: rotate(-45deg); background-color: transparent!important"
          >
            <IconArrowBottomRight
                fillColor="#003d73"
                :size="32"
            ></IconArrowBottomRight>
          </div>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import {computed, ref} from "vue";
import {usePermissions} from "@/components/use/usePermissions";
import {useVersion} from "@/components/use/serviceAssets/useVersion";
import {useModule} from "@/components/use/serviceAssets/documentEditors/Module";
import {useRouter} from "vue-router";
import {useStore} from "vuex";

export default {
  name: "CourseModuleAccordionItem",
  emits: ['delete'],
  components: {
    // EditableDiv,
    // Datepicker,
  },
  props: {
    collectionName: {
      type: String,
      default: 'modules',
      validate: value => value === 'modules'
    },
    documentId: {
      type: String,
      required: true
    },
    courseName: {
      type: String,
      required: true
    },
    index: {
      type: Number,
      required: true,
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    // origin: {
    //   type: String,
    //   default: 'courseModuleAccordionItem'
    // }
  },
  setup(props, {emit}) {
    const store = useStore()
    const router = useRouter()
    const {canEdit} = usePermissions(props)
    const {patchVersionPayloadAttribute} = useVersion()
    const {goToModule, module, moduleName, moduleDocument, decodedModuleName} = useModule(props)
    const collection = computed(() => store.state.collection[props.collectionId] || Object.values(store.state.collection).find(c => c.name === props.collectionName))

    const isCurrent = computed(() => {
      return router.currentRoute?.value.path.endsWith(moduleName.value)
    })
    const moduleTime = ref(new Date())
    const onDelete = deleteArgs => emit('delete', deleteArgs)

    return {
      canEdit,
      collection,
      decodedModuleName,
      moduleDocument,
      goToModule,
      isCurrent,
      module,
      moduleTime,
      onDelete,
      updateAttribute: patchVersionPayloadAttribute,
    }
  }
}
</script>

<style scoped>
.item {
  background-color: #eff0f3;
}

.enter {
  color: #003d73 !important;
  font-size: 110%;
  font-weight: 400;
  margin-right: .5rem;
}

.enterAction {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.uk-accordion-title {
  margin-left: 1rem;
  font-size: 1rem;
  color: black !important;
  font-weight: 500;
}

.isCurrent {
  background-color: rgba(255, 255, 255, 1) !important;
}

.field {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
}

.field label {
  width: 10rem;
}

.relativeTime {
  padding-left: 1rem;
  font-weight: lighter;
  font-size: 80%;
}
</style>
