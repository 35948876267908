<template>
  <Logo class="DeiCLogo">
    <template #default>
      <img :src="`${imageRepoUrl}/deic_logo_footer.png`" alt="Danish e-infrastructure Consortium">

    </template>
  </Logo>
</template>

<script>
import Logo from '@/components/site/Logo'
import {useMediaRepo} from "@c/use/useMediaRepo";
export default {
  name: "DeiCLogo",
  components: {
    Logo,
  },
  setup (props) {
    const {imageRepoUrl} = useMediaRepo(props)
    return {
      imageRepoUrl
    }
  }
}
</script>

<style scoped>
  img {
    filter: saturate(0);
  }
  .cls-1{fill:#fff;}
</style>
