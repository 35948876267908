<template>
  <div class="courseNavigation">
    <div class="navbar uk-section uk-height-viewport">
      <div class="padded">
        <h3
            class="courseName"
            @click="gotoCourse"
        >{{ decodedCourseName }}</h3>
      </div>
      <CourseContentList
          v-if="course"
          :courseName="courseName"
          @new-module-reference="onNewModuleReference"
      ></CourseContentList>
    </div>
    <div class="content" style="height: 100%">
      <slot name="content">

      </slot>
    </div>
  </div>
</template>

<script>
import CourseContentList from "@/components/learningMaterials/course/CourseContentList";
import {usePermissions} from "@/components/use/usePermissions";
import {useCourse} from "@/components/use/serviceAssets/documentEditors/Course";
import {useRouter} from "vue-router";
import {computed, watch} from "vue";
import {useStore} from "vuex";
import {useCollection} from "@u/serviceAssets/useCollection";
import {useDocument} from "@u/serviceAssets/useDocument";

export default {
  name: "LCourseNavigation",
  components: {
    CourseContentList,
  },
  props: {
    collectionName: {
      type: String,
      default: 'courses',
      validate: value => value === 'courses'
    },
    courseName: {
      type: String,
      required: true
    },
    documentNameProperty: {
      type: String,
      default: 'courseName',
      validate: value => value === 'courseName'
    },
  },
  setup(props) {
    const store = useStore()
    const router = useRouter()
    const {loadCollection} = useCollection()
    const {loadDocument} = useDocument()
    const {canEdit} = usePermissions(props)
    // const {patchVersionPayloadAttribute} = useVersion()
    const service = computed(() => store.state.service[store.state.serviceName])
    const collection = computed(() => store.state.collection[props.collectionId] || Object.values(store.state.collection).find(c => c.name === props.collectionName))
    const {course, modules, courseDocument, decodedCourseName} = useCourse(props)

    const onNewModuleReference = newReference => {
      const update = [
        ...modules.value,
        // newReference,
      ]

      console.log('now update course.modules with new module.repoId', newReference, update)
      // patchVersionPayloadAttribute('modules', update)
    }

    const gotoCourse = () => router.push(`/courses/${props.courseName}`)

    watch (service, service => {
      if (service) loadCollection({ collectionName: props.collectionName })
    }, {immediate: true})

    watch(collection, collection => {
      if (collection) loadDocument({
        collectionName: props.collectionName,
        documentName: props.courseName
      }, 'LCourseNavigation.vue')
    }, {immediate: true})

    return {
      canEdit,
      course,
      collection,
      courseDocument,
      decodedCourseName,
      gotoCourse,
      onNewModuleReference,
    }
  }
}
</script>

<style scoped lang="stylus">

.courseNavigation
  display grid
  height 100%
  grid-template-columns 20rem 1fr

  .padded
    padding 0 1rem

  .navbar
    background-color #eff0f3;

  .content
    background-color white
    height 100%

</style>
