<template>
  <div class="card uk-card-small uk-card-default uk-card-body module">
    <div class="content uk-text-left">
      <h3>
        <EditableDiv
            class="title"
            :isEditable="canEdit"
            placeholder="Title"
            :value="decodedModuleName"
            @blur="renameModule"
            @keyup.enter.stop="renameModule"
            @click.stop=""
        ></EditableDiv>
      </h3>
      <p>
        Add this module to any course to work on it.
      </p>
      <DeleteDocumentDialog
          v-if="canEdit"
          elaboration="If any courses include this module they will display a missing document warning."
          @delete-document-confirmed="onDelete"
      ></DeleteDocumentDialog>
    </div>
  </div>
</template>
<script>
import EditableDiv from "@/components/utilities/EditableDiv";
import {usePermissions} from "@/components/use/usePermissions";
import {useVersion} from "@/components/use/serviceAssets/useVersion";
import {useModule} from "@/components/use/serviceAssets/documentEditors/Module";
import {useStore} from "vuex";
import {computed} from "vue";
import DeleteDocumentDialog from "@c/service/document/DeleteDocumentDialog";
export default {
  name: "Module",
  components: {
    DeleteDocumentDialog,
    EditableDiv,
  },
  props: {
    collectionId: {
      type: String,
      required: true
    },
    documentId: {
      type: String,
      required: true
    },
  },
  setup(props) {
    const store = useStore()
    const {canEdit} = usePermissions(props)
    const {deleteVersion} = useVersion()
    const {decodedModuleName, module, moduleName, renameModule, deleteModule} = useModule(props)
    const service = computed(() => store.state.service[store.state.serviceName])


    return {
      canEdit,
      deleteVersion,
      module,
      moduleName,
      onDelete: deleteModule,
      decodedModuleName,
      service,
      renameModule,
    }
  }
}
</script>
<style scoped lang="stylus">
.moduleCard
  max-width 600px

  .card
    min-width 500px
    display grid
    grid-template-columns 225px 1fr


</style>
