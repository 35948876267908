<template>

  <div class="moduleContent uk-height-viewport">
    <LCourseNavigation
        collection-name="courses"
        :courseName="courseName"
        documentNameProperty="courseName"
        :currentModuleName="moduleName"
    >

      <template #content>

        <LLandingPage>

          <template #page>

            <LSections>
              <template #sections>

                <h1>
                  <EditableDiv
                      class="title"
                      :isEditable="canEdit"
                      placeholder="Title"
                      :value="decodedModuleName"
                      @blur="renameModule"
                      @keyup.enter.stop="renameModule"
                  ></EditableDiv>
                </h1>

                <MarkdownContent
                    v-if="collection && document"
                    :collection-id="collection.repoId"
                    :document-id="document.repoId"
                    fieldPath="overview"
                ></MarkdownContent>
                <DocumentArrays
                    class="uk-margin"
                    title="Module Contents"
                    collectionId="documentArrays"
                    :documentId="documentArrayId"
                    @delete="onDeleteParagraph"
                ></DocumentArrays>

                <div
                    v-if="canEdit"
                    class="buttons uk-flex uk-width-1-1 uk-flex-start uk-padding-small uk-border-rounded"
                >
                  <div
                      class="editors uk-text-success"
                  >
                    <div class="editor uk-inline uk-margin-right"
                         @click="appendParagraphType('MarkdownContent')"
                    >
                      <IconPencil
                          fill-color="#003d73"
                      />
                      Write
                    </div>

                    <div class="editor uk-inline uk-margin-right"
                         @click="appendParagraphType('Hint')"
                    >
                      <IconLightbulbOutline
                          fill-color="#003d73"
                      />
                      Hint
                    </div>
                    <div class="editor uk-inline uk-margin-right"
                         @click="appendParagraphType('Example')"
                    >
                      <IconPill
                          fill-color="#003d73"
                      />
                      Example
                    </div>
                    <div class="editor uk-inline uk-margin-right"
                         @click="appendParagraphType('ClickToReveal')"
                    >
                      <IconEyeOffOutline
                          fill-color="#003d73"
                      />
                      Spoiler
                    </div>
                    <div class="editor disabled uk-inline uk-margin-right"
                         @click="appendParagraphType('PythonEditor')"
                    >
                      <IconLanguagePython
                          fill-color="grey"
                      />
                      Python
                    </div>
                  </div>

                </div>
              </template>
            </LSections>


          </template>
        </LLandingPage>

      </template>
    </LCourseNavigation>

  </div>

</template>

<script>

import LSections from "../../../layout/LSections";
import DocumentArrays from "@/components/service/document/DocumentArrays";
import LCourseNavigation from "@/components/learningMaterials/course/LCourseNavigation";
import LLandingPage from "../../../layout/LLandingPage";
import EditableDiv from "@/components/utilities/EditableDiv";
import {useVersion} from "@/components/use/serviceAssets/useVersion";
import {usePermissions} from "@/components/use/usePermissions";
import {useModule} from "@/components/use/serviceAssets/documentEditors/Module";
import {computed, watch} from "vue";
import {useStore} from "vuex";
import {useDocument} from "@u/serviceAssets/useDocument";
import {IconEyeOffOutline, IconLanguagePython} from '../../../index'
import {useCollection} from "@u/serviceAssets/useCollection";
import {GET_OR_CREATE_DOCUMENT} from "@/store/graph/operations";
import {v4 as uuid} from 'uuid'
import dataTypes from '../../../use/serviceAssets/documentEditors/documentEditors'
import {capitalize} from "../../../../../../lib/stringFunctions";

export default {
  name: "ModuleContent",
  components: {
    EditableDiv,
    DocumentArrays,
    IconEyeOffOutline,
    IconLanguagePython,
    LCourseNavigation,
    LLandingPage,
    LSections,
  },
  props: {
    collectionName: {
      type: String,
      default: 'modules',
      validate: value => value === 'modules'
    },
    courseName: {
      type: String,
      required: true
    },
    moduleName: {
      type: String,
      required: true
    },
    documentNameProperty: {
      type: String,
      default: 'moduleName',
      validate: value => value === 'moduleName'
    },
  },
  setup(props) {
    const store = useStore()
    const {canEdit} = usePermissions(props)
    const {loadCollection} = useCollection()
    const {deleteDocument, loadDocument} = useDocument()
    const {patchVersionContent, patchVersionPayloadAttribute} = useVersion()
    const {module, decodedModuleName, renameModule} = useModule(props)
    const document = computed(() => store.state.document[props.documentId] || Object.values(store.state.document).find(d => d.name === (props.documentName || props[props.documentNameProperty])))
    const service = computed(() => store.state.service[store.state.serviceName])
    const collection = computed(() => store.state.collection[props.collectionId] || Object.values(store.state.collection).find(c => c.name === props.collectionName))
    const documentArrayId = computed(() => module.value?.documentArrayId)

    watch(service, service => {
      if (service) {
        loadCollection({collectionName: props.collectionName})
        loadCollection({collectionName: 'documentArrays'})
      }
    }, {immediate: true})
    watch(collection, collection => {
      if (collection) loadDocument({
        collectionName: props.collectionName,
        documentName: props.moduleName
      }, 'Module.vue')
          .catch(err => {
            console.log(130, err)
          })
    }, {immediate: true})

    const appendParagraphType = dataType => {
      dataType = capitalize(dataType)
      // create paragraph document
      const newParagraphName = uuid()
      const newParagraphTemplate = dataTypes[dataType].getDocumentTemplate()
      const newParagraphDocument = {
        serviceName: store.state.serviceName,
        collectionName: 'paragraphs',
        documentName: newParagraphName,
        ...newParagraphTemplate,
        createIfNotExists: true,
      }
      store.dispatch(GET_OR_CREATE_DOCUMENT, newParagraphDocument)
          .then(() => {
            const moduleParagraphsDocument = store.state.document[module.value.documentArrayId]
            const paragraphArray = [...moduleParagraphsDocument.content]
            const newParagraphId = Object.values(store.state.document).find(d => d.name === newParagraphName).repoId
            paragraphArray.push(newParagraphId)

            loadDocument({
              collectionName: 'documentArrays',
              documentName: newParagraphName
            }, 'Module.vue')
                .then(() =>
                    patchVersionContent({
                      serviceId: store.state.serviceName,
                      collectionId: 'documentArrays',
                      documentId: module.value.documentArrayId,
                      value: paragraphArray
                    })
                        .catch(err => console.log(133, err))
                )
                .catch(err => console.log(143, err))
          })
    }

    const onDeleteParagraph = id => {
      const moduleParagraphsDocument = store.state.document[module.value.documentArrayId]
      const paragraphArray = moduleParagraphsDocument.content
          .reduce((array, p) => {
            if (p !== id) array.push(p)
            return array
          }, [])
      console.log(5, paragraphArray)
      patchVersionContent({
        serviceId: store.state.serviceName,
        collectionId: 'documentArrays',
        documentId: module.value.documentArrayId,
        value: paragraphArray
      })
          .then(() => {
            console.log(6, paragraphArray)
            deleteDocument({
              serviceId: store.state.serviceName,
              collectionId: 'paragraphs',
              documentId: id
            })
                .then(() => {
                  console.log(7, 'deleted')
                })
          })
          .catch(err => console.log(822, err))
    }

    return {
      appendParagraphType,
      canEdit,
      collection,
      decodedModuleName,
      document,
      documentArrayId,
      module,
      onDeleteParagraph,
      service,
      updateAttribute: patchVersionPayloadAttribute,
      renameModule,
    }
  }
}
</script>

<style scoped>
.buttons {
  border: 1px solid rgba(229, 231, 235, 0.8);
  background-color: #f5f7fa;
}

.editor,
.editor * {
  color: #003d73;
}

.disabled,
.disabled * {
  color: grey;
}
</style>
