<template>
    <div class="threeColumns">
        <div class="uk-grid-match uk-width-1-1 uk-child-width-1-1@s uk-child-width-1-3@m uk-text-center" uk-grid>
            <slot>
                
            </slot>
            
        </div>
    </div>
</template>
<script>
export default {
    name: 'LThreeColumns',
}
</script>
<style>
.threeColumns {
    display: flex;
    justify-content: stretch;
    align-items: center;
}
.threeColumns .row {
    min-height: 10rem;
}
</style>