<template>
  <Logo class="CHCAALogo">
    <template #default>
      <img
          class="invert"
          src="../../assets/CHCAALogo.png"
          alt="Logo of the Center for Humanities Computing Arhus. The characteristic head of a honey badger"
      >
    </template>
  </Logo>
</template>

<script>
import Logo from '@/components/site/Logo'

export default {
  name: "CHCAALogo",
  components: {
    Logo,
  },
}
</script>

<style scoped lang="stylus">
img
  position relative
</style>
