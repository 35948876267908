<template>
  <div class="LCourses uk-height-viewport">
    <div class="navbar uk-section">
      <div class="padded">
        <!--        make router link to new module: sharedCourseResources -->
        <h3 title="what to link to from here?" class="uk-flex uk-flex-row uk-flex-center">
          <IconSemanticWeb class="uk-margin-small-right"></IconSemanticWeb>
          <span style="">Resources</span>
        </h3>
      </div>

    </div>
    <div class="content" style="height: 100%">
      <slot name="content">

      </slot>
    </div>
  </div>
</template>

<script>

export default {
  name: "LCourses",
}
</script>

<style scoped lang="stylus">
.navbar {
  background-color: #f9f9fb;
}

.LCourses
  display grid
  height 100%
  grid-template-columns 14rem 1fr

  .padded
    padding 0 1rem

  .navbar
    background-color #eff0f3;

  .content
    background-color white
    height 100%

</style>
